import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Components
import { MenuComponent } from "./components/menu/menu.component";
import { HeaderComponent } from "./components/header/header.component";
import { DatePickerComponent } from './components/date-picker/date-picker.component';

import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Services
import { PagarmeService } from './services/pagarme.service';
import { LalamoveService } from './services/lalamove.service';

// Angular
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular Date
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

// Ionic & Cordova Plugins
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ModalDateComponent } from './components/modal-date/modal-date.component';
import { MapComponent } from './components/map/map.component';
import { FormsModule } from '@angular/forms';

registerLocaleData(ptBr);

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule, 
		IonicModule.forRoot(), 
		AppRoutingModule, 
		BrowserAnimationsModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		IonicStorageModule.forRoot(),
		HttpClientModule,
		AngularFireStorageModule,
		AngularFireMessagingModule,
		FormsModule,
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LOCALE_ID, useValue: 'pt'}, 
		DatePipe,
		PagarmeService,
		Geolocation,
		AndroidPermissions,
		LocationAccuracy,
		Diagnostic,
		LalamoveService
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

// Shared module for components
@NgModule({
	declarations:[MenuComponent, HeaderComponent, DatePickerComponent,ModalDateComponent, MapComponent],
	imports: [CommonModule, IonicModule.forRoot(), RouterModule,FormsModule],
	exports: [MenuComponent, HeaderComponent, DatePickerComponent,ModalDateComponent,MapComponent]
})
export class SharedMenuModule {}