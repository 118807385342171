import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirestoreService } from './firestore.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public userInfo: any; // TODO REMOVE?
	
	constructor(
		public auth: AngularFireAuth,
		private fireService: FirestoreService
	) { }

	login(email, pass) {
		return this.auth.signInWithEmailAndPassword(email, pass)
	}

	register(email, pass) {
		return this.auth.createUserWithEmailAndPassword(email, pass)
	}

	resetPassword(email) {
		return this.auth.sendPasswordResetEmail(email)
	}

	isLogged() {
		return this.auth.user;
	}

	isAdmin() {
    return new Promise<{ user: any, isAdmin: boolean }>((resolve) => {
      this.auth.user.subscribe(user => {
        if (user) { 
          const storeId = user.uid;
          this.fireService.readData("users", storeId).subscribe((user) => {
            const isAdmin = user.role === "admin";
            resolve({ user, isAdmin });
          });
        } else {
          resolve({ user: null, isAdmin: false });
        }
      });
    });
  }

	logout(): Promise<void> {
		return this.auth.signOut();
	}

	// TODO THIS WILL RETURN NULL THEN CRASH WITH A TYPE ERROR IF USER HIT F5 OR CHANGE PAGES
	// MAYBE USE SUBSCRIPTION IN ISLOGGED()
	userLogged(): Promise<string> {
		return this.auth.currentUser.then(user => {
			// console.log("User", user);
			return user.uid;
		});
	}

	// TODO REMOVE? USELESS NOW
	public getStoreId(): Promise<string> {
		return new Promise(resolve => {
			this.isLogged().subscribe(user => {
				resolve(user.uid);
			});
		});		
	}
}
