import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

	@Input() status: string;
	private admin = false

	constructor(
		private navController: NavController,
		private auth: AuthService,
		private fireStore: FirestoreService
	) { }

	 ngOnInit() {
		this.isAdmin();
	}
	
	private async isAdmin() {
		return new Promise<boolean>((resolve) => {
			this.auth.isLogged().subscribe(user => {
				const storeId = user.uid;
				this.fireStore.readData("users", storeId).subscribe((user) => {
					this.admin = user.role === "admin"
					resolve(user);
				});
			});
		});
	}

	public order(status: 'processing' | 'preparing' | 'waiting-delivery' | 'in-delivery' | 'completed' | 'canceled') {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				status: status,
			}
		}
		return this.navController.navigateRoot('orders', navigationExtras);
	}

	openPage(url: string) {
		return this.navController.navigateRoot(url);
	}
	

}