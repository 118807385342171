// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyChbAJ79fVqXA0wFIy-1_Fmt4R1Vyu710c",
    authDomain: "gaya-food-47bca.firebaseapp.com",
    databaseURL: "https://gaya-food-47bca.firebaseio.com",
    projectId: "gaya-food-47bca",
    storageBucket: "gaya-food-47bca.appspot.com",
    messagingSenderId: "840780731376",
    appId: "1:840780731376:web:ebe4aecdaf7400b5f04335",
    measurementId: "G-WWR6RNZW4P",
    vapidKey: "BI7Fzly7xj-bFnizlnAe_bODGFCIOuntefLpyJEaQWRG1LxcRvC3uvw9u0E7740NozUXmP8kTTjYJEnPF_FMCKQ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
