import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LalamoveService {

	constructor(
		private http: HttpClient
	) { }

	// Http options to force no cache use and send correct content type
	private options = {
		headers: new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
			'Pragma': 'no-cache',
			'Expires': '0',
			'Content-Type': "application/json"
		})
	}

	//private apiLocation: string = 'https://gayaloja.morsetecnologia.com/lalamove/'; // TODO ADD REAL URL LOCATION
	private apiLocation: string = "http://localhost:3333/"
	/**
	 * Request a quotation. 
	 * Will return an object containing `quotationId`, `stops`, `special requests`, `price` and `currency` based on information provided
	 */
  public getQuotation(quotationBody: any): Observable<any> {
    let url: string = this.apiLocation + "quotation";
    return this.http.post<any>(url, quotationBody);
  }


	public placeOrder(orderBody: OrderBody): Observable<any> {
		let url:string = this.apiLocation + 'orders';
		return this.http.post(url, orderBody);
	}

	/**
	 * Get orders
	 * @param orderId An Order ID
	 */
	public getOrders(orderId?: string): Observable<Object> {
		let url:string = orderId ? this.apiLocation + 'orders/' + orderId : this.apiLocation + 'orders';
		return this.http.get(url, this.options);
	}
}

// UV_FIORINO special to SP
type ServiceType = "WALKER" | "MOTORCYCLE" | "CAR" | "SEDAN" | "VAN" | "TRUCK175" | "TRUCK330" | "TRUCK550" | "UV_FIORINO";

interface KeyValue {
	[key: string]: string
}

/**
 * @param stops Minimum 2
 * @param scheduleAt Pick up time in UTC timezone and ISO 8601 format; omit this if you are placing an immediate order
 * @param specialRequests Particularities for each city, like tolls
 * @param item [Recommended] Object of delivery item's information
 * @param cashOnDelivery An object containing the amount of cash paid to the driver. This service is only available in selected markets
 */
export interface QuotationBody {
	data: {
		serviceType: ServiceType
		stops: DeliveryStop[]
		language: "pt_BR"
		scheduleAt?: string
		specialRequests?: string[]
		isRouteOptimized?: boolean
		item?: ItemInformation
		cashOnDelivery?: { amount: number }
	}
}

/**
 * @param address Street address in plain text.
 */
interface DeliveryStop {
	coordinates: {
        lat: string,
        lng: string
    },
    address: string
}

interface DeliveryStopResponse extends DeliveryStop {
	stopId: string
}

/**
 * Information about the delivered item. This will help Lalamove drivers deliver more reliably and efficiently. 
 * Note that supported values for `weight`, `categories`, `handlingInstructions` differ by cities and vehicle types.
 * @param quantity Number of items
 * @param weight Weight of items in ENUM -> Ex: `"LESS_THAN_3_KG"`
 * @param categories Categories of the items
 * @param handlingInstructions Handling instructions for items
 */
interface ItemInformation {
	quantity: string
	weight: string
	categories: string[]
	handlingInstructions: string[]
}

export interface QuotationResponse {
	data: {
		expiresAt: string // EX: "2022-06-15T21:04:26.00Z"
		isRouteOptimized: boolean
		language: "PT_BR"
		priceBreakdown: {
			base: string
			currency: "BRL"
			extraMileage: string
			total: string // Ex: "51.28"
			totalBeforeOptimization: string
			totalExcludePriorityFee: string
			vat: string
		}
		quotationId: string // Ex: "1537179247918129211"
		scheduleAt: string // EX: "2022-06-15T21:04:26.00Z"
		serviceType: ServiceType
		stops: DeliveryStopResponse[]
	}	
}

/**
 * @param quotationId `quotationId` from quotations request
 * @param sender.name Name of the sender. This information will be displayed to the driver.
 * @param sender.phone Phone number of the sender. This information will be displayed to the driver. Must use e.164 format (ex: +5548123456789)
 * @param recipients An array of `DeliveryDetails`, containing recipient contact and instruction per stop
 * @param isRecipientSMSEnabled Send delivery updates via SMS to THE recipient, or the recipient of the LAST STOP for multi-stop orders once the order has been picked-up by the driver. Default to `true`
 * @param isPODEnabled Request driver to carry out "Proof Of Delivery" for all stops in the order. Default to false.
 * @param partner Specify Partner's name for effective tracking and organization. This field is only applicable for channel partners.
 * @param metadata An object with key-value pairs containing client-specific information
 * @example metada example => `{ "restaurantOrderId": "1234", "restaurantName": "Rustam's Kebab" }`
 */
export interface OrderBody {
	data: {
		quotationId: string
		sender: {
			stopId: string
			name: string
			phone: string
		}
		recipients: DeliveryDetails[]
		isRecipientSMSEnabled?: boolean
		isPODEnabled?: boolean
		partner?: string
		metadata?: KeyValue
	}
	
}

/**
 * @param stopId Identifier for each stop returned from Lalamove.
 * @param name Name of the given stop's recipient
 * @param phone Phone number of the given stop's recipient. Must be a valid number with region code (ex: +5548123456789)
 * @param remarks Additional info about the delivery. eg. building, floor and flat. Use newline \r\n for better readability. Up to 1500 characters.
 * @example remarks example => "ORDER#94\r\n1. Tshirt จำนวน 1\r\n2. Hoodie จำนวน 1\r\n"
 */
interface DeliveryDetails {
	stopId: string
	name: string
	phone: string
	remarks?: string
}

export interface OrderResponse {
	data: {

	}
}