import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { format, parseISO } from 'date-fns';

// https://github.com/date-fns/date-fns

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
	@Input() presentation: Presentation = "month-year";
	public displayFormat:string = "DD.MM.YYYY HH:mm";
	public date: string; // The value of the datetime as a valid ISO 8601 datetime string.

	constructor(
		private popover: PopoverController
	) { }

	ngOnInit() {}

	/**
	 * Format date to a more readable string
	 * @param value ISO 8601 datetime string
	 */
	formatDate(value: string): string {
		switch (this.presentation) {
			case "month-year":
				this.displayFormat = "DD.MM.YYYY HH:mm";
				return format(parseISO(value), 'MM yyyy');

			case "time":
				this.displayFormat = "HH:mm";
				return format(parseISO(value), 'HH:mm');
		
			default:
				return value;
		}
	}

	goBack() {
		return this.popover.dismiss();
	}

	select() {
		const data = this.date ? this.date : this.formatDate(new Date().toISOString());
		return this.popover.dismiss(data, 'selected');
	}

}

type Presentation = "time" | "month-year" | "time-date" | "date-time";