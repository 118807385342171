import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MenuController, NavController } from "@ionic/angular";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { FirestoreService } from "src/app/services/firestore.service";
import { AuthService } from "src/app/services/auth.service";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "src/environments/environment";
import { StoreApp } from "src/app/services/interfaces.service";
import { parse, format } from "date-fns";
import { ptBR } from "date-fns/locale";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  message: any = null;
  logo: string = "";
  defaultLogo: string =
    "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y";

  @ViewChild("popoverAvatar") popover;
  @ViewChild("audioOption") audioPlayerRef: ElementRef;
  isOpen = false;
  public openingHours: any = [];
  public isStoreOpen: boolean;
  constructor(
    private menu: MenuController,
    private navController: NavController,
    private afMessaging: AngularFireMessaging,
    private firebase: FirestoreService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.requestPermission();
    this.getLogo();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  public toggleMenu(): Promise<boolean> {
    return this.menu.toggle("main");
  }

  public store() {
    return this.navController.navigateRoot("store");
  }

  public exit() {
    this.auth.logout();
    return this.navController.navigateRoot("login");
  }



  async requestPermission() {
    let id = await this.auth.getStoreId();
    console.log(id);
    const messaging = getMessaging();
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
          .then((currentToken) => {
            console.log(currentToken);
            if (currentToken) {
              this.firebase.updateIntoDocumet("stores/", id, {
                tokenPush: currentToken,
              });
            } else {
              console.log("Nenhum token registrado");
            }
          })
          .catch((err) => {
            console.log("Erro ao gerar token", err);
          });
      } else {
        console.log("notificação negada");
      }
    });
  }
  listen() {
    console.log("listen");
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Nova mensagem recebida ", payload);
      this.message = payload;
    });
  }

  // upDateItem() {
  //   this.firebase.getDataUpdate().subscribe((data) => {
  //     console.log("Dados atualizados:", data);
  //     this.playAudio();
  //   });
  // }
  // playAudio() {
  //   this.audioPlayerRef.nativeElement.play();
  // }

  handleClick = () => {
    this.requestPermission();
  };

  private async getLogo() {
    const id = await this.auth.getStoreId();
    this.firebase.readData("stores", id).subscribe((data) => {
      this.logo = data && data.logo;
    });
  }
  isOpenStore() {
    this.auth.isLogged().subscribe((user) => {
      // console.log(user, user.uid);
      const id = user.uid;

      this.firebase
        .readData("stores/", id)
        .subscribe((storeData: StoreApp) => {
          this.openingHours = storeData.openingHours;
          const dataAtual = new Date();
          const dataString = format(dataAtual, 'yyyy-MM-dd');
          const data = parse(dataString, 'yyyy-MM-dd', new Date());
          const diaDaSemana = format(data, 'EEEE', { locale: ptBR });
          this.openingHours.forEach(element => {
            if(element.day.name === diaDaSemana){
              this.isStoreOpen = element.isClosed
              return;
            }
          });
        });
    });
  }
}
